<template>

  <main class="main_v8 content_edit">
    <header class="edit_header">
      <button v-if="route.params.id !== 'new'" class="delete" @click.prevent="showMessage.postDelete = true">Delete Page</button>
      <button class="cancel" @click.prevent="clearSaveInterval(); clearPageLocalData(); router.go(-1);">Cancel</button>
      <button class="save" @click.prevent="savePage();">Done</button>
    </header>
    <div v-if="!isDesktop()" class="type_choose_mobile">
      <p>Page Type:</p>

      <div
        class="page_type"
        :class="{ active_pt: data.type === 'txt' }"
        @click.prevent="typeSwitch('txt')"
      >Normal</div>

      <div
        class="page_type"
        :class="{ active_pt: data.type === 'pdf' }"
        @click.prevent="typeSwitch('pdf')"
      >PDF</div>

      <div
        class="page_type"
        :class="{ active_pt: data.type === 'video' }"
        @click.prevent="typeSwitch('video')"
      >Video</div>
    </div>

    <p v-if="!isDesktop()" class="subtitle tileSubtext" :style="{color: data.categoryColor}" v-text="'Page #'"></p>
    <input v-if="!isDesktop()" class="title" v-model="data.title" @click="here()" placeholder="Enter Title...">
    
    <!-- <div class="edit_button" @click="showMessage.optionToggle = !showMessage.optionToggle"></div> -->
    <div class="txt_edit" v-if="data.type === 'txt'">
      <editor v-model="data.html" @paste="TMCEPaste"
        api-key="no-api-key"
        :init="{
          menubar: false,
          //plugins: 'lists link image imagetools codesample nonbreaking paste',
          plugins: 'lists  image codesample nonbreaking paste',
          toolbar: 'undo redo | image | codesample | alignleft aligncenter alignright | bullist numlist | bold underline italic | h1 h2 h3',
          object_resizing: 'img',
          branding: false,
          file_picker_types: 'file image media',
          content_style: `@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800'); @import url('/mce_editor.css'); body { font-family: Inter; }; ol,ul { padding-left: 20px;}`,
          image_uploadtab: true,
          paste_data_images: true,
          codesample_languages: [
            { text: 'HTML/XML', value: 'markup' },
            { text: 'JavaScript', value: 'javascript' },
            { text: 'TypeScript', value: 'typescript' },
            { text: 'CoffeeScript', value: 'coffeescript' },
            { text: 'CSS', value: 'css' },
            { text: 'SCSS', value: 'scss' },
            { text: 'Less', value: 'less' },
            { text: 'PHP', value: 'php' },
            { text: 'PHP Template', value: 'php-template' },
            { text: 'Ruby', value: 'ruby' },
            { text: 'Python', value: 'python' },
            { text: 'Python REPL', value: 'python-repl' },
            { text: 'Java', value: 'java' },
            { text: 'C', value: 'c' },
            { text: 'C#', value: 'csharp' },
            { text: 'C++', value: 'cpp' },
            { text: 'Scala', value: 'scala' },
            { text: 'Bash', value: 'bash' },
            { text: 'Kotlin', value: 'kotlin' },
            { text: 'Markdown', value: 'markdown' },
            { text: 'Perl', value: 'perl' },
            { text: 'R', value: 'r' },
            { text: 'SQL', value: 'sql' },
            { text: 'Diff', value: 'diff' },
            { text: 'Json', value: 'json' },
            { text: 'Objective-C', value: 'objective-c' },
            { text: 'Shell Session', value: 'shellsession' },
            { text: 'Visual Basic .NET', value: 'vb' },
            { text: 'Go', value: 'go' },
            { text: 'Lua', value: 'lua' },
            { text: 'Rust', value: 'rust' },
            { text: 'Swift', value: 'swift' },
            { text: 'Web Assembly', value: 'wasm' },
            { text: 'GraphQL', value: 'graphql' },
            { text: 'Makefile', value: 'makefile' },
            { text: 'YAML', value: 'yaml' },
            { text: '1C:Enterprise', value: '1c' },
            { text: 'ARM Assembly', value: 'armasm' },
            { text: 'BASIC', value: 'basic' },
            { text: 'Delphi', value: 'delphi' },
            { text: 'Django', value: 'django' },
            { text: 'HTTP', value: 'http' },
          ],
          image_advtab: true,
          //imagetools_cors_hosts: ['localhost:8080'],
          //imagetools_toolbar: 'rotateleft rotateright | flipv fliph | editimage imageoptions',
          content_css: '/content.css',
          importcss_append: true,
          paste_preprocess: function (pl, o) {
            o.content = smart_paste(o.content);
          },
          images_upload_handler: tinymceImageUploadHandler,
        }"
      />
    </div>

    <div v-if="data.type === 'pdf'" class="pdf_edit">
      <span v-if="!(['',undefined].includes(data.src))" class="delete-upload" @click="showMessage.deletePDF = true;"></span>
      <PSPDFKitContainer v-if="!(['',undefined].includes(data.src))" :pdfFile="data.src" :keyIndex="`a878a8`" @loaded="handleLoaded" />
      <DropZonePdf
        v-if="data.src === '' || data.src === undefined"
        :index="0"
        :height="150"
        :docid="route.params.id"
        @drop.prevent="drop"
        @change="selectedFile"
        @uploadCompleted="uploadComplete"
        @uploadStarted='uploadStarted'
      />
    </div>

    <div v-if="data.type === 'video'" class="video_edit">
      <div v-if="!(['',undefined].includes(data.src))" class="delete_video" @click="showMessage.deleteVideo = true;">
        <span></span>
      </div>
      <div v-if="!(['',undefined].includes(data.src))" class="video_box">
        <!-- <iframe 
          :src = "data.src"
          style="border: none"
          height="480"
          width="800"
          allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture; fullscreen;"
        ></iframe> -->
        <div v-if="!(['',undefined].includes(data.src))" class="video_box">
          <HlsVideoPlayer 
            :src="data.src"
            :muted="false"
            :controls="true"
            :autoplay="false"
            :skipDuration="10"
            @timeupdate="onTimeUpdate"
            @pause="onVideoPause"
            @play="onVideoPlay"
            @ended="onVideoEnded"
          />
        </div>
      </div>
      <div v-if="['',undefined].includes(data.src)" id="videoloader" >
        <DropZone :docid="route.params.id" @drop.prevent="drop" @change="selectedFile" @uploadCompleted="uploadVideoComplete" />
      </div>
    </div>

    <div class="updown page_info" :class="{active_info: !showMessage.optionToggle}">
      <span class="options_toggle" :class="!isDesktop() && !showMessage.optionToggle ? 'options_toggle_more' : null " @click="[!isDesktop() ? [showMessage.optionToggle = !showMessage.optionToggle] : null]">
        <span v-if="!isDesktop()">MORE</span>
        <span v-else-if="data.type === 'txt' && draft">DRAFT PAGE EDIT</span>
        <span v-else-if="data.type === 'txt'">PAGE EDIT</span>
        <span v-else-if="data.type === 'pdf'">PDF PAGE EDIT</span>
        <span v-else-if="data.type === 'video'">VIDEO PAGE EDIT</span>
      </span>

      <div class="tag" v-if="!info.hideSharedOption">
        <div>
          <Toggle v-model="published" /> {{ "Published" }}
        </div>
      </div>

      <div class="page_type_desktop" v-if="isDesktop()">
        <p class="subheader">Page Type</p>
        <div class="page_type" :class="{active_pt: data.type === 'txt'}" @click.prevent="typeSwitch('txt')">Normal</div>
        <div class="page_type" :class="{active_pt: data.type === 'pdf'}" @click.prevent="typeSwitch('pdf')">PDF</div>
        <div class="page_type" :class="{active_pt: data.type === 'video'}" @click.prevent="typeSwitch('video')">Video</div>
      </div>

      <div v-if="isDesktop()" class="tag">
        <p>Title</p>
        <input type="text" v-model="data.title">
      </div>

      <!-- <div class="tag">
        <p>Subtitle or page #</p>
        <input type="text" v-model="data.subtitle">
      </div> -->

      <div v-if="route.params.id !== 'new'" class="tag">
        <p>Location</p>
        <p class="breaadcrumbs_draft" v-text="((!library || !section || !book) ? info.breadcrumbs : library + ' / ' + section + ' / ' + book)"></p>
        <div class="button secondary" style="margin-top: 12px;" @click="showMessage.movePage = true;">Move Page</div>
      </div>

      <div class="tag" v-if="info.imageEditor">
          <ImgX :src="info.imageEdit.slice(4).slice(0, -1)" :imageEditor="true"
                @editedImageUploadCompleted="editedImageUploadComplete"
                @editedImageClosed="editedImageClose">
          </ImgX>
      </div>

      <div v-if="data.type === 'txt'" style="position: relative; height: auto;">
        <div class="uploader">
          <DropZoneContentEdit :index="0" :height="150" :docid="route.params.id" @drop.prevent="drop" @change="selectedFile" @uploadCompleted="uploadComplete" @uploadStarted='uploadStarted' />
        </div>
        
        <p class="subheader">Files</p>
        <div class="field">
          <div class="files">
            <div class="doc" v-for="(doc, index) in data.files" :key="index" :class="doc[1]">
              <a :href="doc[3]" target="_blank" :download="doc[0]">{{doc[0]}}</a>
              <span class="cross" @click="removeFiles(index)"></span>
            </div>

            <div class="add_img_label" @click="uploadContentEdit">+ ADD</div>

          </div>
        </div>
      </div>
      <div v-if="!isDesktop()" class="button close_button" @click="showMessage.optionToggle = !showMessage.optionToggle">Close</div>
    </div>

    <div class="location_modal" v-if="showMessage.movePage" @click="Object.keys(search).forEach(v => search[v] = false)">
      <h3 @click="here()">Move Page</h3>

      <div class="select" :class="search.library ? 'select_active' : null">
        <p>Library:</p>

        <label for="library">

          <input id="library" v-if="library != '' && library != librarySearch" type="text" name="library" v-model="librarySearch" :placeholder="'Search for Library...'"
          @click.stop="search.library = true; library = null; section = null;librarySearch = '';  sectionSearch = ''; bookSearch = '';">

          <p v-else v-text="library" style="background-color: #099F451A"
          @click.stop="search.library = true; here(); section = null; library = null; librarySearch = ''; sectionSearch = ''; bookSearch = ''"></p>
        </label>
        
          <div class="list" v-if="search.library">
            <template v-for="(item, index) in libraries" :key="index">
              <div v-if="item.toLowerCase().includes(librarySearch.toLowerCase())" @click.stop="librarySearch = item; library = item; search.library = false; getSectionsList(index);">{{ item }}</div>
            </template>
          </div>
      </div>

      <div class="select" :class="search.section ? 'select_active' : null">
        <p>Section:</p>
        <label for="section">
          <input v-if="library === null" readonly type="text" name="section" value="Search for Section..." @click.stop style="background-color: #f0f0f0;">
          <input v-else type="text" name="section" v-model="sectionSearch" :placeholder="section != null ? section : 'Search for Section...'"
          :style="[section == sectionSearch ? {backgroundColor: '#099F451A'} : null]"
          @click.stop="search.section = !search.section; sectionSearch = ''; bookSearch = ''; section = null; book = null;">
        </label>
          <div class="list" v-if="search.section">
            <template v-for="(item, index) in sections" :key="index">
              <div v-if="item.toLowerCase().includes(sectionSearch.toLowerCase())" @click.stop="sectionSearch = item; section = item; search.section = false; getBooksList(index);">{{ item }}</div>
            </template>
          </div>
      </div>

      <div class="select" :class="search.book ? 'select_active' : null">
        <p>Book:</p>
        <label for="book">
          <input v-if="section === null" readonly type="text" name="book" value="Search for Book..." @click.stop style="background-color: #f0f0f0;">
          <input v-else type="text" name="book" v-model="bookSearch" :placeholder="book != null ? book : 'Search for Book...'"
          :style="[book == bookSearch ? {backgroundColor: '#099F451A'} : null]"
          @click.stop="bookSearch = '';search.book = !search.book">
        </label>
          <div class="list" v-if="search.book">
            <template v-for="(item, index) in books" :key="index">
              <div v-if="item.toLowerCase().includes(bookSearch.toLowerCase())" @click.stop="bookSearch = item; book = item; newBookId=index; search.book = false;">{{ item }}</div>
            </template>
          </div>
      </div>

      <div class="button_set">
        <div class="button secondary" @click.stop="cancelMove">Cancel</div>
        <div v-if="library == null || section == null || book == null" class="button disabled" @click.stop>Move</div>
        <div v-else class="button" @click.stop = "move">Move</div>
      </div>
    </div>

    <transition name="fade">
      <Shade v-if="Object.values(showMessage).includes(true)"
        :style="[showMessage.movePage ? {zIndex: 3} : null]"
        @click.prevent="Object.keys(showMessage).forEach(v => showMessage[v] = false); cancelMove();"></Shade>
    </transition>

    <div v-if="showMessage.titleWarning" class="title_warning">
      <h3>Error - No Title for Page</h3>
      <p>You must enter a page title in order to save the page. The subtitle is optional, but can be used for helpful user hints, like “Step 1”.
      </p>
      <div class="btns">
        <Btn class="button" v-text="'OK'" @click.prevent="titleWarningDelay"></Btn>
      </div>
    </div>

    <div v-if="showMessage.emptyPDF || showMessage.emptyVideo" class="title_warning">

      <h3 v-if="showMessage.emptyPDF">Missing PDF File Warning</h3>
      <h3 v-if="showMessage.emptyVideo">Missing File Warning</h3>

      <p v-if="showMessage.emptyPDF">You can’t save PDF page without PDF file.</p>
      <p v-if="showMessage.emptyVideo">You can’t save Video page without Video.</p>

      <div class="btns">
        <Btn class="button" v-text="'OK'" @click.prevent="showMessage.emptyPDF = false; showMessage.emptyVideo = false;"></Btn>
      </div>
    </div>

    <transition name="fade">
      <div v-if="showMessage.contentDelete" class="delete_anything">
        <h3>Content Deletion Warning <span @click.prevent="showMessage.contentDelete = false;"></span></h3>
        <div class="trash_can"></div>
        <p class="warn">
          If you change the page type, any content you have created in the current page type will be DELETED. Please make sure you want to make this choice before changing the page type.
          <br/><br/>
          If you are sure, select the green button below.
        </p>
        <div class="buttons">
          <Btn class="button" v-text="'Change Page Type'" @click.prevent="showMessage.contentDelete = false; changeType();" style="padding: 0;"></Btn>
          <Btn class="button secondary" v-text="'Cancel Changes'" @click.prevent="showMessage.contentDelete = false;"></Btn>
        </div>
      </div>
    </transition>

    <transition name="fade">
      <div v-if="showMessage.postDelete || showMessage.deletePDF || showMessage.deleteVideo" class="delete_anything">
        <h2 v-if="showMessage.postDelete">Content Deletion Warning</h2>
        <h2 v-if="showMessage.deletePDF">Document Deletion Warning</h2>
        <h2 v-if="showMessage.deleteVideo">Video Deletion Warning</h2>
        <div class="trash_can"></div>
        <p v-if="showMessage.postDelete" class="warn">
          If you delete the page, any text, images or other content included in the page will be DELETED.
          <br/><br/>
          If you are sure, select the green button below.
        </p>
        <p v-if="showMessage.deletePDF" class="warn">
          This document WILL BE DELETED!
          <br/><br/>
          If you are sure you want to delete, select the green button below.
        </p>
        <p v-if="showMessage.deleteVideo" class="warn">
          This video WILL BE DELETED!
          <br/><br/>
          If you are sure you want to delete, select the green button below.
        </p>
        <div class="buttons">
          <Btn v-if="showMessage.postDelete" class="button" v-text="'Delete Page'" @click.prevent="deletePage"></Btn>
          <Btn v-if="showMessage.deletePDF || showMessage.deleteVideo" class="button" v-text="'Delete'" @click.prevent="data.src=''; showMessage.deletePDF = false; showMessage.deleteVideo = false;"></Btn>
          <Btn class="button secondary" v-text="'Cancel'" @click.prevent="showMessage.postDelete = false; showMessage.deletePDF = false; showMessage.deleteVideo = false;"></Btn>
        </div>
      </div>
    </transition>
  </main>
</template>

<script setup>
import {defineAsyncComponent, inject, ref, watch} from "vue";
import {useRoute, useRouter} from "vue-router";
import {useStore} from "vuex";

import HlsVideoPlayer from '../components/HlsVideoPlayer.vue'
import Btn from '@/components/buttons/button.vue';
import Shade from '@/components/shade/shade.vue';
import PSPDFKitContainer from '@/components/pspdfkit/PSPDFKitContainer';
import ImgX from '@/components/imageeditor/image.vue';
import Editor from '@tinymce/tinymce-vue';
import Toggle from '@vueform/toggle';
import '../assets/js/tinymce/tinymce';
import '../assets/js/tinymce/themes/silver/theme'
import '../assets/js/tinymce/icons/default'
import '../assets/js/tinymce/skins/ui/oxide/skin.css'
import '../assets/js/tinymce/skins/content/default/content.css'
import '../assets/js/tinymce/plugins/paste';
import '../assets/js/tinymce/plugins/advlist';
import '../assets/js/tinymce/plugins/image';
import '../assets/js/tinymce/plugins/lists';
import '../assets/js/tinymce/plugins/charmap';
import '../assets/js/tinymce/plugins/hr';
import '../assets/js/tinymce/plugins/codesample';
import '../assets/js/tinymce/plugins/nonbreaking';
import '../../src/assets/default.scss';

//todo update them. Now they work with errors
//import '../assets/js/tinymce/plugins/link';
//import '../assets/js/tinymce/plugins/imagetools';


//ASYNC COMPONENTS
const DropZone = defineAsyncComponent(
    () => import('../components/dropzones/cloudflare_video.vue'),
);

const DropZonePdf = defineAsyncComponent(
    () => import('../components/dropzones/cloudflare_pdf.vue'),
);

const DropZoneContentEdit = defineAsyncComponent(
    () => import('../components/dropzones/cloudflare_attachments.vue'),
);

const router = useRouter();
const route = useRoute();
const store = useStore();

const apiPath = inject("apiPath");

//let libraries = ref(['New York / South Region / Plant 1 / Line 1 / New York / South Region / Plant 1 / Line2  ','New York / South Region / Plant 1 / Line 1 ','New York / South Region / Plant 1 / Line 1 ','New York / South Region / Plant 1 / Line 1 ','New York / South Region / Plant 1 / Line 1 ','New York / South Region / Plant 1 / Line 1 ','New York / South Region / Plant 1 / Line 1 ','New York / South Region / Plant 1 / Line 1 ','New York / South Region / Plant 1 / Line 1 New York / South Region / Plant 1 / Line 1 ','New York / South Region / Plant 1 / Line 1 ','New York / South Region / Plant 1 / Line 1 ','New York / South Region / Plant 1 / Line 1 ','New York / South Region / Plant 1 / Line 1 ','New York / South Region / Plant 1 / Line 1 ','New York / South Region / Plant 1 / Line 1 ']);
//let sections = ref(['123123','121212','131313','232323','321321','323232','212121','111111','222222','333333']);
//let books = ref(['123123','121212','131313','232323','321321','323232','212121','111111','222222','333333']);
let libraries = ref({});
let sections = ref({});
let books = ref({});
let library = ref(null);
let section = ref(null);
let book = ref(null);
let newBookId = ref(null);
let librarySearch = ref('');
let sectionSearch = ref('');
let bookSearch = ref('');
let search = ref({
  library: false,
  section: false,
  book: false,
});

const onTimeUpdate = (data) => {
  console.log('Progress:', data.progress)
  console.log('Current Time:', data.currentTime)
  console.log('Duration:', data.duration)
}

const onVideoPause = (data) => {
  console.log('Video paused at:', data.currentTime)
}

const onVideoPlay = (data) => {
  console.log('Video playing at:', data.currentTime)
}

const onVideoEnded = (data) => {
  console.log('Video ended')
}

function cancelMove() {
  showMessage.value.movePage = false;
  Object.keys(search.value).forEach(v => search[v] = false);
  librarySearch.value = '';
  sectionSearch.value = '';
  bookSearch.value = '';
  library.value = null;
  section.value = null;
  book.value = null;
}

async function move() {
  if (newBookId.value !== '0') {
    let newParentArticle = await sendFetch(
        'entity/article/get',
        {
              "articleid": newBookId.value
        }
    );
    if (newParentArticle) {
      data.value.articleid = newBookId.value;
      draft.value = (newParentArticle.draft === '1');
    }
  } else {
      data.value.articleid = newBookId.value;
      draft.value = true;
  }
  showMessage.value.movePage = false;
}

function here() {
  console.log(data.value.title, 'data.value.title');
  // console.log(library.value, 'library');
  // console.log(section.value, 'section');
  // console.log(book.value, 'book');
  // console.log(librarySearch.value, 'librarySearch');
  // console.log(sectionSearch.value, 'section');
  // console.log(bookSearch.value, 'bookSearch');
  // console.log(search.value, 'search');
}

let showMessage = ref({
  'postDelete': false,
  'contentDelete': false,
  'optionToggle': false,
  'deletePDF': false,
  'deleteVideo': false,
  'titleWarning': false,
  'emptyPDF': false,
  'emptyVideo': false,
  'movePage': false,
});

let data = ref({
  'type': 'txt',
  'categoryColor': null,
  'files': [],
  'id': null,
  'postid': 'new',
  'articleid': null,
  'article': null,
  'title': '',
  'html': '',
  'subtitle': '',
  'shared': '1',
  'draft': '0',
  'creatorid': '',
  'tags': [],
});

let info = ref({
  'hideSharedOption': false,
  'newType': null,
  'processing': true,
  'imageEditor': false,
  'imageEdit': '',
  'currentUploadUrl': '',
  'breadcrumbs': '',
});

let published = ref(true);
let draft = ref(false);

getData();

//todo change watcher for postUploadFiles after DropZoneContentEdit component rework
watch(store.state.postUploadFiles, (value) => {
  data.value.files = value;
});

watch(published, (value) => {
  if (value && draft.value !== !value) {
    draft.value = !value;
  }
  data.value.shared = value ? '1' : '0';
});

watch(draft, (value) => {
  if (value) {
    if (published.value !== !value) {
      published.value = !value;
    }
    data.value.draft = '1';
    data.value.shared = '0';
    info.value.hideSharedOption = true;
  } else {
    data.value.draft = '0';
    info.value.hideSharedOption = !((data.value.creatorid === store.state.user.id) || (route.params.id === 'new'));
  }
});

let localDataUpdateInterval;
async function getData() {
  if (localStorage.getItem('pageData') !== null) {
    let pageData = JSON.parse(localStorage.getItem('pageData'));
    if (pageData.postid === route.params.id) {
      store.state.pageData = JSON.parse(localStorage.getItem('pageData'));
      store.state.pageInfo = JSON.parse(localStorage.getItem('pageInfo'));
    } else {
      clearPageLocalData();
    }
    //console.log('check', store.state.pageData);
  }
  let pageData = null;
  if (route.params.id !== 'new') {
    pageData = await sendFetch(
        'postData',
        {
          "postid": route.params.id
        }
    );
  } else {
    if (localStorage.getItem('createDraft') === '1') {
      localStorage.removeItem('createDraft');
      draft.value = true;
      data.value.draft = '1';
      data.value.shared = '0';
      info.value.hideSharedOption = true;
      data.value.articleid = '0';
      data.value.shared = '0';
      data.value.draft = '1';
    } else {
      data.value.articleid = localStorage.getItem('currentBookId');
      data.value.shared = localStorage.getItem('currentBookShared');
      data.value.draft = localStorage.getItem('currentBookDraft');
      draft.value = (data.value.draft === '1');
    }
  }
  if (localStorage.getItem('pageStartState') === null) {
    if (pageData !== null) {
      localStorage.setItem('pageStartState', JSON.stringify(pageData));
    } else {
      localStorage.setItem('pageStartState', JSON.stringify(data.value));
    }
  }
  if (pageData !== null) {
    if (JSON.stringify(pageData) !== localStorage.getItem('pageStartState') || store.state.pageData === undefined) {
      localStorage.setItem('pageStartState', JSON.stringify(pageData));
      Object.keys(pageData).forEach((key) => {
        data.value[key] = pageData[key];
      });
    } else {
      Object.keys(store.state.pageData).forEach((key) => {
        data.value[key] = store.state.pageData[key];
      });
      Object.keys(store.state.pageInfo).forEach((key) => {
        info.value[key] = store.state.pageInfo[key];
      });
    }
  } else if (store.state.pageData !== undefined) {
    Object.keys(store.state.pageData).forEach((key) => {
      data.value[key] = store.state.pageData[key];
    });
    Object.keys(store.state.pageInfo).forEach((key) => {
      info.value[key] = store.state.pageInfo[key];
    });
  }
  store.state.postUploadFiles = data.value.files; //todo remove after DropZoneContentEdit component rework
  draft.value = (data.value.draft === '1');
  published.value = (data.value.shared === '1');
  info.value.hideSharedOption = (data.value.creatorid !== store.state.user.id);
  if (data.value.articleid === '0') {
    info.value.breadcrumbs = 'Your Content Library / Drafts / Create Now Book';
  } else if (data.value.article === null || data.value.article === undefined) {
    info.value.breadcrumbs = 'Systems doesn\'t know this location';
  } else {
    let breadcrumbs = [];
    data.value.article.breadcrumbs.forEach((element) => {
      breadcrumbs.push(element.title);
    });
    info.value.breadcrumbs = breadcrumbs.join(' / ');
  }

  savePageLocalData();
  localDataUpdateInterval = setInterval(() => {
    if (route.name !== 'Page') {
      clearInterval(localDataUpdateInterval);
    }
    savePageLocalData();
  }, 1000);

  await getLibrariesList();
  info.value.processing = false;
}

async function getLibrariesList() {
  let librariesData = await sendFetch(
      'entity/location/find',
      {
        'filter': JSON.stringify(
            {
              "machinekb": 1,
              "shared": 1
            }
        )
      }
  );
  let libsIds = librariesData.map(item => item.slocationid);
  let librariesBreadcrumbs = await sendFetch(
      'entity/location/breadcrumbsList',
      {
        'ids': JSON.stringify(libsIds)
      }
  );
  libraries.value = {};
  //todo test build
  libraries.value['unpublished'] = 'My Content Library'
  Object.entries(librariesBreadcrumbs).forEach(([key, value]) => {
    if (value) {
      libraries.value[key] = value.map(item => item.title).join(' / ');
    }
  });
}

async function getSectionsList(locationId) {
  let sectionsData = await sendFetch(
      'entity/section/find',
      {
        'filter': JSON.stringify(
            {
              "slocationid": locationId,
              "permitted": 1
            }
        )
      }
  );
  sections.value = {};
  if (locationId === 'unpublished') {
    sections.value['0'] = 'Drafts Section';
  }
  if (sectionsData) {
    sectionsData.forEach((item) => {
      sections.value[item.sectionid] = item.Name;
    });
  }
}

async function getBooksList(sectionId) {
  let booksData = await sendFetch(
      'entity/article/find',
      {
        'filter': JSON.stringify(
            {
              "parentid": sectionId,
              "permitted": 1
            }
        )
      }
  );
  books.value = {};
  if (sectionId === '0') {
    books.value['0'] = 'Create Now Book';
  }
  if (booksData) {
    booksData.forEach((item) => {
      books.value[item.articleid] = item.name;
    });
  }
}

async function savePage() {
  if (info.value.processing) {
    return;
  }
  info.value.processing = true;

  let content = JSON.parse(JSON.stringify(data.value));

  //todo rework backend to change this
  ['articleid','article', 'permissions', 'categoryColor', 'type', 'views', 'likes', 'comments', 'tags','postid','id', 'shared', 'draft','creatorid'].forEach((element) => {
    if (content.hasOwnProperty(element)) {
      delete content[element];
    }
  });

  switch (data.value.type) {
    case 'txt':
      content = await preprocessTxtContent(content);
      break;
    case 'pdf':
      break;
    case 'video':
      break;
    default:
      console.log('Wrong page type: ', data.value.type);
      window.alert('Wrong page type');
      info.value.processing = false;
      return;
  }

  await savePageData(content);

  info.value.processing = false;
}

async function preprocessTxtContent(content) {
  let editorDom = new DOMParser().parseFromString(content.html, 'text/html');
  let linksTags = editorDom.getElementsByTagName('a');
  for (let linksTag of linksTags) {
    linksTag.setAttribute('target.value', '_blank');
  }
  data.value.html = editorDom.body.innerHTML;
  content.html = editorDom.body.innerHTML;
  if ('serviceWorker' in navigator) {
    let imgTags = editorDom.getElementsByTagName('img');
    let base64ImgTags = [];
    let base64ImgText = [];
    let base64ImgInfo = [];
    for (let imgTag of imgTags) {
      if (imgTag.getAttribute('src')?.includes('data:image/')) {
        base64ImgTags.push(imgTag);
        base64ImgText.push(imgTag.getAttribute('src'));
        base64ImgInfo.push(await getImageSize(imgTag.getAttribute('src')));
      }
    }
    if (base64ImgText.length > 0) {
      let links = [];
       return await fetch(`${apiPath}cloudflareGetUploadLinksImages?count=${base64ImgText.length}&token=${store.state.usertoken}`
      ).then((res) => res.json()).then((response) => {
        links = response;
        let uploadLinks = response;
        for (let key = 0; key < links.length; key++) {
          let link = links[key].replace('https://upload.','https://') + '/public'; //`public` - can be changed to `thumb` if the little one needed
          base64ImgTags[key].setAttribute('alt', 'processing...');
          base64ImgTags[key].setAttribute('src', link);
          if (!base64ImgTags[key].outerHTML.includes('<img width="')) {
            base64ImgTags[key].outerHTML = base64ImgTags[key].outerHTML.replace('<img', '<img width="' + base64ImgInfo[key].width + '" height="' + base64ImgInfo[key].height + '"'
                + 'style="width:'+base64ImgInfo[key].width+'px;height:'+base64ImgInfo[key].height+'px;"'
            );
          }
        }

        if (uploadLinks.length > 0) {
          let info =
              {
                ['imgText']: base64ImgText,
                ['links']: uploadLinks,
                ['docId']: route.params.id,
              };
          navigator.serviceWorker.addEventListener('message', event => {
            console.log(event.data);
          });
          navigator.serviceWorker.ready.then(worker => {
            worker.active.postMessage({
              type: 'savePostImages',
              data: info,
            });
          });
        }

        data.value.html = editorDom.body.innerHTML;
        content.html = editorDom.body.innerHTML;
        return content;
      }).catch((error) => {
        console.log('Looks like there was a problem with backend: \n', error);
        return false;
      });
    } else {
      return content;
    }
  }

  console.log('Looks like there was a problem with serviceWorker');
  return false;
}

async function savePageData(content) {
  let formData = new FormData();
  if (route.params.id !== undefined && route.params.id !== 'new') {
    formData.append('postid', route.params.id.toString());
  }
  formData.append('articleid', data.value.articleid !== null ? data.value.articleid : 0);
  if (store.state.contentIndex) {
    formData.append('order', store.state.contentIndex);
  }
  formData.append('content', JSON.stringify(content));
  formData.append('contenttype', data.value.type);
  formData.append('shared', data.value.shared);
  formData.append('draft', data.value.draft);
  formData.append('token', store.state.usertoken);

  fetch(`${apiPath}editPost`, {
    method: 'POST',
    body: formData,
  }).then((res) => res.json()).then((response) => {
    clearSaveInterval();
    clearPageLocalData();
    store.state.edit = true;
    if (response.action === 'insert') {
      let postSortIds = JSON.parse(localStorage.getItem('postSortIds'));
      if (postSortIds) {
        postSortIds = postSortIds.map(item => item === 'new' ? response.postid : item);
      }
      if (postSortIds && postSortIds.length > 0) {
        sendFetch(
            'entity/post/resort',
            {
              "ids": JSON.stringify(postSortIds)
            }
        ).then(() => {
          store.commit('pageSaved'); //todo check: do we need this?
          if (draft.value && data.value.articleid === '0') {
            router.push({ name: 'Article', params: { id: 'unpublished'}});
          } else {
            router.go(-1);
          }
        });
      } else {
        if (draft.value) {
          router.push({ name: 'Article', params: { id: 'unpublished'}});
        } else {
          router.go(-1);
        }
      }
    } else {
      store.commit('pageSaved'); //todo check: do we need this?
      clearSaveInterval();
      router.go(-1);
    }
  }).catch((error) => {
    console.log('Looks like there was a problem: \n', error);
  });
}

function deletePage() {
  info.value.processing = true;
  console.log('delete page and go to Book page');
  if (data.value.postid !== undefined && data.value.postid !== 'new') {
    sendFetch(
        'entity/post/delete',
        {
          "postid": data.value.postid
        }
    ).then(() => {
      info.value.processing = false;
      store.commit('pageSaved');
      clearSaveInterval();
      router.go(-1);
    });
  } else {
    clearSaveInterval();
    router.go(-1);
  }
}

function titleWarningDelay() {
  title.valueWarning = false;
  if (!isDesktop()) {
    setTimeout(() => {
      showMessage.value.optionToggle = true;
    }, 300);
  }
}

function typeSwitch(type) {
  if (data.value.type === type) {
    return;
  }

  info.value.newType = type;
  switch (data.value.type) {
    case 'txt':
      if (!(['',undefined].includes(data.value.html))) {
        showMessage.value.contentDelete = true;
        return;
      }
      break;
    case 'pdf':
      if (!(['',undefined].includes(data.value.src))) {
        showMessage.value.contentDelete = true;
        return;
      }
      break;
    case 'video':
      if (!(['',undefined].includes(data.value.src))) {
        showMessage.value.contentDelete = true;
        return;
      }
      break;
    default:
      info.value.newType = null;
      return;
  }

  data.value.type = type;
  info.value.newType = null;
}
function changeType(){
  data.value.type = info.value.newType;
  data.value.html = '';
  data.value.src = '';
  data.value.files = [];

  info.value.newType = null;
}

function clearSaveInterval() {
  clearInterval(localDataUpdateInterval);
}

function savePageLocalData() {
  let newData = JSON.stringify(data.value);
  if (newData !== localStorage.getItem('pageData')) {
    console.log('New data has been saved locally');
    localStorage.setItem('pageData', newData);
  }
  let newInfo = JSON.stringify(info.value);
  if (newInfo !== localStorage.getItem('pageInfo')) {
    console.log('New info has been saved locally');
    localStorage.setItem('pageInfo', newInfo);
  }
}

function clearPageLocalData(dataKeys = null) {
  if (dataKeys === null) {
    localStorage.removeItem('pageData');
    localStorage.removeItem('pageInfo');
    localStorage.removeItem('pageStartState');
    localStorage.removeItem('currentBookId');
    localStorage.removeItem('currentBookShared');
    localStorage.removeItem('currentBookDraft');
    delete store.state.pageData;
    delete store.state.pageInfo;
    return;
  }
  let data = localStorage.getItem('pageData');
  if (data === null) {
    return;
  }
  data = JSON.parse(data);
  dataKeys.forEach((key) => {
    delete data[key];
  });
  if (Object.keys(data).length > 0) {
    localStorage.setItem('pageData', JSON.stringify(data));
  } else {
    localStorage.removeItem('pageData');
  }
}

function uploadStarted(path) {
  console.log("upload started: " + path);
  info.value.currentUploadUrl = path;
}

function uploadComplete(index, lCurrentUrl = info.value.currentUploadUrl) {
  console.log("upload completed: " + lCurrentUrl);
  console.log("index: " + index);
  data.value.src = index>=0 ? lCurrentUrl : '';
}

function uploadVideoComplete(index, lCurrentUrl) {
  console.log("upload completed: " + lCurrentUrl);
  console.log("index: " + index);
  data.value.src = (index>=0 && lCurrentUrl !== undefined) ? lCurrentUrl : '';
}

function editedImageUploadComplete(newUrl) {
  if (newUrl !== undefined && newUrl !== null && newUrl !== '') {
    info.value.imageEdit = 'url(' + newUrl + ')';
    info.value.imageEditor = false;
  } else {
    console.log('Image is not uploaded');
  }
}

function editedImageClose() {
  info.value.imageEditor = false;
}

function uploadContentEdit() {
  let fileUpload = document.getElementsByClassName('dropzone_files_contentedit');
  console.log("in uploadContentEdit",fileUpload);
  fileUpload[0].click();
}

function removeFiles(index) {
  data.value.files.splice(index, 1);
}

function getImageSize(dataURL) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = function() {
      resolve({ width: img.width, height: img.height });
    };
    img.onerror = function() {
      reject(new Error("Failed to load image."));
    };
    img.src = dataURL;
  });
}

function TMCEPaste(e) {
  info.value.processing = true;
  setTimeout(() => {
    let imgTags = e.currentTarget.getElementsByTagName('img');
    for (let imgTag of imgTags) {
      if (!imgTag.outerHTML.includes('<img width="') && imgTag.src.includes('data:image/')) {
        imgTag.outerHTML = imgTag.outerHTML.replace('<img', '<img width="' + imgTag.width + '" height="' + imgTag.height + '"'
            + 'style="width:'+imgTag.width+'px;height:'+imgTag.height+'px;"'
        );
      }
    }
    info.value.processing = false;
  }, 300);
}

function smart_paste(str) {
  str = str.replaceAll(/(?:class|className)=(?:["']\W+\s*(?:\w+)\()?["']([^'"]+)['"]/g, ``);
  str = str.replaceAll(/(?:id|className)=(?:["']\W+\s*(?:\w+)\()?["']([^'"]+)['"]/g, ``);
  if (str.includes('https://www.youtube.com/') || str.includes('https://youtube.com/') || str.includes('https://youtu.be/')) {
    str = str.replaceAll(/(?<!["'`])((?:http|https|ftp):\/\/\S+)(?!["'`])/gm, `<a href='$&' target.value='_blank' rel='nofollow'>$&</a>`);
  }
  str = str.replaceAll(/(?:<iframe[^>]*)(?:(?:\/>)|(?:>.*?<\/iframe>))/gm, ``);
  str = str.replaceAll(/(?:<script[^>]*)(?:(?:\/>)|(?:>.*?<\/script>))/gm, ``);
  str = str.replaceAll(/(?:<style[^>]*)(?:(?:\/>)|(?:>.*?<\/style>))/gm, ``);
  str = str.replaceAll(/\bwidth="(\d+)"/g, ``);
  str = str.replaceAll(/\bheight="(\d+)"/g, ``);
  str = str.replaceAll(/position:(fixed)/gm, ``);
  str = str.replaceAll(/position:( fixed)/gm, ``);
  return str;
}

function tinymceImageUploadHandler(blobInfo, success, failure, progress) {
  const reader = new FileReader();

  reader.onload = function() {
    const base64String = reader.result;
    success(base64String);
  };

  reader.readAsDataURL(blobInfo.blob());
}

function isDesktop() {
  return (window.innerWidth > 970 && window.innerWidth > window.innerHeight);
}

async function sendFetch(url, data = null) {
  let formData = new FormData();
  formData.append('token', store.state.usertoken);
  if (data !== null) {
    Object.keys(data).forEach(function(key) {
      formData.append(key, data[key]);
    });
  }
  return await fetch(`${apiPath}` + url, {
    method: 'POST',
    body: formData,
  }).then((res) => res.json()).then((response) => {
    if (response.result) {
      return response.result;
    } else {
      console.log('Looks like there was a problem: \n', response.error);
      if (response.error === 'Wrong token') {
        window.location.reload();
      }
      if (response.error === '403' && url.includes('postData')) {
        window.location.href = "/403";
      }
      return false;
    }
  }).catch((error) => {
    console.log('Looks like there was a problem: \n', error);
    return false;
  });
}

//todo remove or use after components rework
function drop() {}
function selectedFile() {}
function handleLoaded(instance) {
  console.log("PSPDFKit has loaded: ", instance);
}
</script>

<style lang="scss" scoped>
.breaadcrumbs_draft{
  padding: 9px 8px;
  font-size: 1rem;
  min-height: 39px;
  background-color: rgba(120, 120, 120, 0.05);
  box-sizing: border-box;
  border-radius: 8px;
  height: auto !important;
  @media screen and (min-width: 1025px){
    /* max-width: 332px; */
  }
}
.location_modal{
  position: fixed;
  padding: 16px;
  z-index: 4;
  border-radius: 16px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: 800px;
  max-width: 650px;
  height: 100%;
  width: 100%;
  background-color: #fff;
  box-sizing: border-box;
  color: #787878;
  @media screen and (min-width: 1025px){
  }
  @media screen and (min-width: 901px) and (max-width: 1024px){
  }
  @media screen and (min-width: 481px) and (max-width: 900px){
    max-width: calc(100% - 32px);
  }
  @media screen and (max-width: 480px), screen and (max-height: 480px) and (orientation: landscape){
    max-height: calc(100% - 32px);
    max-width: calc(100% - 32px);
  }
  h3{
    margin-top: 0px;
  }
  .select{
    padding: 10px 6px 16px;
    margin-bottom: 12px;
    background-color: #f8f8f8;
    border-radius: 8px;
    transition: all 0.2s;
    p{
      margin-top: 0px;
      margin-bottom: 8px;
    }
    label{
      position: relative;
      height: 40px;
      display: inline-block;
      width: 100%;
      input,p{
        border-radius: 8px;
        background-color: #fff;
        box-sizing: border-box;
        flex-wrap: wrap;
        width: 100%;
        position: relative;
        font-size: 1rem;
        color: #787878;
        border: 1px solid #BCBCBC;
        outline: none;
        text-indent: 45px;
        background-image: url(../../public/img/search.svg);
        background-position: left 8px center;
        background-repeat: no-repeat;
        padding: 0px;
      }
      input{
        height: 40px;
      }
      p{
        min-height: 40px;
        padding-left: 45px;
        text-indent: 0px;
        padding: 8px 4px 4px 45px;
      }
    }
    .list{
      max-height: calc(100% - 70px);
      overflow-x: auto;
      padding-right: 8px;
      padding-top: 8px;
      >div{
        padding: 8px 16px;
        margin-bottom: 4px;
        background-color: #fff;
        border-radius: 16px;
        border: 1px solid #BCBCBC;
        border-radius: 8px;
      }
      >div:hover{
        background-color: #f8f8f8;
      }
    }
  }
  .select_active{
    position: absolute;
    z-index: 1;
    width: calc(100% - 32px);
    box-sizing: border-box;
    left: 16px;
    top: 80px;
    bottom: 70px;
    @media screen and (min-width: 1025px){
    }
    @media screen and (min-width: 901px) and (max-width: 1024px){
    }
    @media screen and (min-width: 481px) and (max-width: 900px){
    }
    @media screen and (max-width: 480px), screen and (max-height: 480px) and (orientation: landscape){
      top: 68px;
    }
  }
  .button_set{
    position: absolute;
    bottom: 16px;
    left: 16px;
    right: 16px;
    .button{
      width: calc(50% - 8px);
    }
    .button:first-child{
      margin-right: 16px;
    }
  }
}
.updown{
  .options_toggle::before,.options_toggle::after{
    width: calc(50% - 70px);
  }
}
.delete_video{
  height: 40px;
  background-color: #2C2F3596;
  width: calc(100% - 100px);
  border-radius: 20px;
  position: absolute;
  top: 30px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
  span{
    display: inline-block;
    height: 100%;
    width: 40px;
    background-image: url(../../public/img/delete_white.svg);
    background-position: center;
    background-repeat: no-repeat;
  }
}
.page_type_desktop{
  /* margin-top: 30px; */
  display: flex;
  flex-wrap: wrap;
  .subheader{
    display: inline-block;
    margin: 0;
    height: 42px;
    line-height: 42px;
    color: #787878;
    font-size: 1rem;
    font-weight: 400;
    white-space: nowrap;
    width: 100%;
  }
}
.title{
  margin: 0 !important;
}
.toolong{
  position: absolute;
  left: 0;
  height: 100%;
  padding: 0 3px 0 0;
  text-indent: 8px;
  background-color: #f0f0f0;
  direction: ltr;
}
.add_tag{
  width: 456px;
  margin-left: -228px;
  position: fixed;
  top: 150px;
  left: 50%;
  padding: 32px 32px 115px;
  border-radius: 16px;
  background-color: #fff;
  z-index: 3;
  box-sizing: border-box;
  max-width: 100%;
  font-size: 14px;
  h2{
    font-size: 24px;
    margin: 0 0 32px 0;
  }
  input{
    display: block;
    margin-bottom: 16px;
    border: none;
    outline: none;
    height: 40px;
    background-color: rgba(120, 120, 120, 0.05);
    border-radius: 8px;
    text-indent: 8px;
    width: 100%;
  }
  .btns{
    text-align: center;
    position: absolute;
    bottom: 32px;
    width: 100%;
    left: 0;
    display: flex;
    padding: 0 32px;
    box-sizing: border-box;
    .button{
      padding: 0;
    }
    :first-child{
      width: 100px;
      margin-right: 16px;
    }
    :last-child{
      flex: 1;
    }
  }
}
.delete-upload{
  position: absolute;
  height: 40px;
  width: 40px;
  background-color: #fff;
  border-radius: 20px;
  top: -15px;
  right: -15px;
  background-image: url(../../public/img/close_gray.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 75%;
  z-index: 2;
  box-shadow: rgba(99, 99, 99, 0.2) 0 2px 8px 0;
}
.content_edit{
  .field{
    min-height: 40px;
    /* margin-bottom: 16px; */
    position: relative;
    height: auto;
    overflow: auto;
    p{
      display: inline-block;
      margin: 0;
      line-height: 40px;
      font-weight: 500;
      font-size: 14px;
      width: 112px;
    }
    span{
      margin: 0;
      line-height: 40px;
      font-weight: 500;
      font-size: 14px;
      color: #405FE8;
    }
    input:not([type="file"]),select{
      background-color: rgba(120, 120, 120, .05);
      border: 0;
      height: 40px;
      border-radius: 8px;
      position: absolute;
      width: 100%;
      padding-left: 16px;
      box-sizing: border-box;
    }
    select{
      option{
        width: 200px;
      }
    }
    input[type="file"]{
      opacity: 0;
      visibility: hidden;
      position: absolute;
    }
    .add_img_label{
      color: #787878;
      background-color: rgba(120, 120, 120, .05);
      font-size: 0.76rem;
      border-radius: 8px;
      height: 39px;
      line-height: 39px;
      display: inline-block;
      padding: 0 20px;
      box-sizing: border-box;
      vertical-align: top;
      cursor: pointer;
    }
    select{
      -webkit-appearance: none;
      -moz-appearance: none;
      color: #787878;
      background-image: url(../../public/img/arr_down.svg);
      background-repeat: no-repeat;
      background-position: right center;
    }
    input:focus,input:active,select:focus,select:active{
      outline: 0;
    }
  }
}
.files{
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: auto;
  .doc{
    background-color: rgba(120, 120, 120, 0.05);
    display: inline-block;
    padding: 0 30px 0 40px;
    border-radius: 8px;
    box-sizing: border-box;
    margin-bottom: 8px;
    margin-right: 6px;
    width: 200px;
    background-image: url(../../public/img/book/doc.svg);
    background-repeat: no-repeat;
    background-position: 14px center;
    cursor: pointer;
    background-size: 10%;
    position: relative;
    height: 39px;
    overflow: hidden;
    a{
      margin: 0;
      font-size: 0.76rem;
      white-space: nowrap;
      overflow: hidden;
      height: 39px;
      line-height: 39px;
      text-decoration:none;
      color: #555;
    }
    p{
      margin: 0;
      font-size: 0.76rem;
      white-space: nowrap;
      overflow: hidden;
      height: 39px;
      line-height: 39px;
    }
    .cross{
      position: absolute;
      right: 0;
      top: 0;
      display: inline-block;
      background-image: url(../../public/img/gray_cross.svg);
      background-repeat: no-repeat;
      background-position: center;
      height: 39px;
      width: 32px;
      background-color: #f8f8f8;
    }
  }
  .pdf_img{
    background-image: url(../../public/img/book/pdf.svg);
  }
  .img_img{
    background-image: url(../../public/img/book/img.svg);
  }
}

.uploader {
  position: absolute;
  height: 90px;
  width: 90px;
  right: -15px;
  top: 20px;
  text-align: center;
  .gaugePercent{
    font-size: 13px;
  }
}
.txt_edit{
  margin-top: 12px;
  min-width: calc(100% - 508px);
  max-width: calc(70% - 41px);
  height: calc(100% - 40px);
  @media screen and (max-width: 971px){
    height: calc(100% - 210px);
  }
  @media screen and (max-width: 1366px) and (min-width: 1024px) and (max-height: 1024px)
  and (min-height: 744px) and (orientation: landscape){
    max-width: 66%;
  }
  @media screen and (max-width: 1024px) and (min-width: 600px) and (max-height: 1370px)
  and (min-height: 961px) and (orientation: portrait) {
    max-width: none;
  }
  @media screen and (max-width: 599px){
    max-width: none;
  }
}
.video_edit,.pdf_edit{
  margin-top: 20px;
  position: relative;
  width: calc(70% - 20px);
  height: calc(100% - 40px);
  .video_box,.pdf_box{
    border-radius: 16px;
    position: relative;
    margin-bottom: 24px;
    margin-right: 500px;
    video{
      aspect-ratio: 16/9;
      width: 100%;
      height: auto;
    }
    iframe{
      aspect-ratio: 16/9;
      width: 100%;
      height: auto;
    }
  }
  .pdf_box{
    max-height: 71vh;
    overflow: hidden;
    box-sizing: border-box;
  }
}
.video_edit{
  .video_box{
    width: 100%;
    iframe{
      border-radius: 20px;
    }
  }
}
.edit_header{
  height: 64px;
  width: 100%;
  background-color: #fff;
  position: fixed;
  z-index: 1; 
  top: 0;
  left: 0;
  box-shadow: 0 2px 8px 2px rgba(0, 0, 0, 0.06);
  padding: 16px 32px;
  box-sizing: border-box;
  button{
    border: 0;
    background-color: #fff;
    outline: none;
  }
  .save{
    right: 32px;
    height: 64px;
    color: #787878;
  }
  .cancel{
    right: 105px;
    height: 64px;
    color: #787878;
  }
  .delete{
    left: 20px;
    height: 64px;
    display: inline-block;
    color: #CD180A;
  }
  .delete,.cancel,.save{
    position: absolute;
    font-weight: 500;
    cursor: pointer;
    font-family: Inter,sans-serif;
    top: 0;
    font-size: 1rem;
  }
}
  
.title_warning{
  position: absolute;
  top: 50% !important;
  -webkit-transform: translate(-50%, -50%); //?wtf eto dlya kross-broyzernosti
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  left: 50%;
  padding: 32px;
  border-radius: 16px;
  background-color: #fff;
  z-index: 3;
  box-sizing: border-box;
  width: 370px;
  max-width: calc(100% - 32px);
  height: 320px;
  h3{
    text-align: left;
    margin: 0 0 32px 0;
  }
  p{
    text-align: left;
    font-size: 0.82rem;
    margin: 0 auto;
    color: #787878
  }
  .btns{
    text-align: center;
    position: absolute;
    bottom: 32px;
    width: 100%;
    left: 0;
    padding: 0 32px;
    box-sizing: border-box;
    .button{
      width: 100%;
      min-width: 160px;
    }
  }
}
  .page_info{
    position: absolute;
    max-width: 30%;
    width: 508px;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: #fff;
    padding: 0 32px 32px 32px;
    box-sizing: border-box;
    z-index: 2;
    transform: translateY(0px);
    .close_button{
      margin-top: 60px;
      position: relative;
    }
    .close_button::before{
      content: '';
      position: absolute;
      height: 2px;
      width: 100%;
      background: #2525251A;
      top: -30px;
      left: 0px;
    }
    .options_toggle::before,.options_toggle::after{
      width: calc(50% - 120px);
    }
    .tag{
      flex-direction: column;
      p{
        line-height: 1.2rem;
        height: 1.2rem;
        width: 100%;
        margin-bottom: 8px;
      }
      input{
        min-height: 39px;
      }
      /* .button{
        max-width: 332px;
      } */
    }
    >div{
      // margin-top: 20px;
      .subheader{
        color: #787878;
        font-size: 1rem;
        font-weight: 400;
        flex-grow: 1
      }
      input:not([type="checkbox"]){
        background-color: rgba(120, 120, 120, .05);
        border: 0;
        height: 40px;
        border-radius: 8px;
        /* max-width: 332px; */
        width: 100%;
        padding-left: 16px;
        box-sizing: border-box;
      }
      input:focus,input:active{
        outline: 0;
      }
      input[type="file"]{
        opacity: 0;
        visibility: hidden;
        position: absolute;
      }
      .add_label{
        color: #787878;
        background-color: rgba(120, 120, 120, .05);
        font-size: 12px;
        border-radius: 8px;
        height: 32px;
        line-height: 32px;
        display: inline-block;
        padding: 0 20px;
        cursor: pointer;
      }
      .hashtags{
        display: inline-block;
        div{
          padding: 0 20px;
          display: inline-block;
          cursor: pointer;
          background-color: rgba(40, 132, 199, 0.05);
          color: #2884C7;
          margin-right: 8px;
          margin-bottom: 8px;
          border-radius: 8px;
          span{
            font-size: 12px;
            height: 32px;
            line-height: 32px;
            padding-right: 20px;
            background-image: url(../../public/img/blue_cross.svg);
            background-repeat: no-repeat;
            background-position: center right;
          }
        }
      }
    }
  }
  .page_type{
    font-size: 0.76rem;
    font-weight: 500;
    line-height: 39px;
    height: 39px;
    padding: 0px 16px;
    border-radius: 8px;
    display: inline-block;
    color: #787878;
    background-color: rgba(120, 120, 120, 0.05);
    cursor: pointer;
    margin-right: 8px;
  }
  .page_type:last-child{
    margin-right: 0;
  }
  .active_pt{
    color: #fff;
    background-color: #006C3E;
  }
  .content_edit{
    bottom: 0;
    top: 64px;
    .title{
      margin: 32px 0 20px 0;
      font-weight: 600;
    }
  }
  @media screen and (max-width: 1300px) and (orientation: landscape){
    .page_info{
      max-width: 35%;
      padding: 0 20px 32px 20px;
    }
  }
  @media screen and (max-width: 1024px) and (min-width: 600px) and (max-height: 1370px)
  and (min-height: 961px) and (orientation: portrait) {
    .page_info{
      z-index: 3;
    }
    .edit_button{ 
      height: 44px;
      width: 44px;
      display: inline-block;
      background-image: url(../../public/img/page/edit.svg);
      background-position: center;
      background-repeat: no-repeat;
      position: absolute;
      top: 62px;
      right: 9px;
      background-size: 26px;
    }
    .subtitle{
      margin-bottom: 0;
    }
    .txt_edit,.pdf_edit,.video_edit{
      max-height: 83%;
      width: 100%;
    }
    .type_choose_mobile{
      margin-top: 16px;
      margin-bottom: 16px;
      display: flex;
      p{
        display: inline-block;
        margin: 0;
        font-weight: 500;
        color: #787878;
        flex-grow: 1;
        height: 32px;
        line-height: 32px;
      }
    }
    .content_edit{
      .title{
        margin: 8px 0 20px 0;
      }
    }
    header{
      height: 64px !important;
    }
    .main_v8{
      top: 64px !important;
    }
    .video_edit,.pdf_edit{
      max-width: inherit;
      .video_box,.pdf_box{
        margin-right: 0;
      }
    }
    .content_edit{
      overflow-y: hidden;
      .title{
        width: 100%;
      }
    }
    .active_info{
      transform: translateY(100%) translateY(-53px);
    }
    .page_info{
      position: absolute;
      max-width: none;
      width: 100%;
      top: initial;
      bottom: 0;
      right: 0;
      background-color: #fff;
      padding: 0 32px 32px 32px;
      box-sizing: border-box;
      box-shadow: 0px -2px 8px 2px rgba(0, 0, 0, 0.06);
      border-radius: 24px 24px 0 0;
      transition: all .3s;
      >div{
        margin-top: 16px;
        .subheader{
          display: inline-block;
          width: 152px;
        }
      }
    }
  }
  @media screen and (max-width: 1024px) and (min-width: 600px) and (max-height: 1370px)
  and (min-height: 900px) and (orientation: portrait) {
    .subtitle{
      color: #006C3E;
    }
    .title{
      margin: 0px;
      padding: 0px;
      border: none;
      font-size: 1rem;
      line-height: 46px;
      height: 46px;
      background-color: rgba(120, 120, 120, 0.05);
      border-radius: 8px;
      text-indent: 8px;
      outline: none;
      width: 100%;
      font-family: Inter;
    }
    .title,.subtitle{
      display: block;
    }
    .content_edit{
      padding: 0 16px;
    }
    .page_info{
      padding: 0 16px 16px;
    }
  }
  // IPAD PRO
  @media screen and (max-width: 1366px) and (min-width: 1024px) and (max-height: 1024px)
  and (min-height: 744px) and (orientation: landscape){
    .page_info{
      max-width: 33%;
      padding: 0 24px 32px 24px;
    }
  }
  // IPAD MINI
  @media screen and (width: 1024px) and (height: 768px) and (orientation: landscape){
    .page_info{
      max-width: 390px;
    }
  }
  // SURFACE PRO 7
  @media screen and (width: 1368px) and (height: 912px) and (orientation: landscape){
    .form{
      max-width: 800px;
    }
  }
  @media screen and (max-width: 599px) and (orientation: portrait), screen and (max-height: 500px) and (orientation: landscape){
    .updown{
      .options_toggle::before,.options_toggle::after{
        width: calc(50% - 100px);
      }
      .options_toggle_more::before,.options_toggle_more::after{
        width: calc(50% - 60px);
      }
    }
    .delete_video{
      height: 30px;
      width: calc(100% - 30px);
      border-radius: 15px;
      top: 10px;
    }
    .page_info{
      z-index: 3;
    }
    .title,.subtitle{
      display: block;
    }
    .subtitle{
      color: #006C3E;
    }
    .txt_edit,.video_edit,.pdf_edit{
      width: 100%;
      height: 84%;
    }
    .content_edit{
      top: 44px;
      .subtitle{
        height: 16px;
        margin: 7px 0 0 0;
      }
      .title{
        margin: 0px;
        padding: 0px;
        border: none;
        font-size: 1rem;
        line-height: 46px;
        height: 46px;
        background-color: #7878780D;
        border-radius: 8px;
        text-indent: 8px;
        outline: none;
        width: 100%;
        font-family: Inter;
      }
    }
    .edit_button{
      height: 44px;
      width: 44px;
      display: inline-block;
      background-image: url(../../public/img/page/edit.svg);
      background-position: center;
      background-repeat: no-repeat;
      position: absolute;
      top: 51px;
      right: 9px;
    }
    .edit_header{
      height: 44px;
      .save{
        height: 44px;
        right: 10px;
      }
      .cancel{
        height: 44px;
        right: 76px;
      }
      .delete{
        height: 44px;
        left: 10px;
        right: auto;
        span{
          display: none;
        }
      }
    }
    .title_warning{
      height: auto;
      width: 90%;
      margin-left: 0;
      padding: 20px 20px 88px 20px;
      h3{
        margin: 0 0 16px 0;
      }
      p{
        text-align: left;
        padding: 10px 0 10px;
      }
      .trash_can{
        background-size: 70%;
        margin: 0 auto 10px;
        height: 170px;
      }
      .btns{
        padding: 20px 0 0;
        bottom: 20px;
        .button{
          width: calc(100% - 40px);
        }
      }
    }
    .content_edit{
      overflow-y: hidden;
      .field{
        margin-bottom: 0;
      }
    }
    .video_edit,.pdf_edit{
      max-width: none;
      margin-top: 16px;
      .video_box,.pdf_box{
        margin-right: 0;
        padding: 0;
        width: 100%;
        background: none;
        iframe{
          border-radius: 18px;
          overflow: hidden;
        }
      }
    }
    .type_choose_mobile{
      margin-top: 10px;
      position: relative;
      height: 39px;
      p{
        display: inline-block;
        margin: 0;
        font-size: 1rem;
        font-weight: 500;
        color: #787878;
        height: 39px;
        line-height: 39px;
      }
      .page_type{
        padding: 0 16px;
        margin-right: 0;
        margin-left: 8px;
        position: absolute;
      }
      div:nth-child(2){
        right: 130px;
      }
      div:nth-child(3){
        right: 72px;
      }
      div:last-child{
        right: 0;
      }
    }
    .active_info{
      transform: translateY(100%) translateY(-53px);
    }
    .page_info{
      position: absolute;
      max-width: none;
      width: 100%;
      top: initial;
      bottom: 0;
      right: 0;
      background-color: #fff;
      padding: 0 16px 32px 16px;
      box-sizing: border-box;
      box-shadow: 0px -2px 8px 2px rgba(0, 0, 0, 0.06);
      border-radius: 24px 24px 0 0;
      transition: all .3s;
      .tag{
        display: flex;
        flex-direction: column;
        p{
          width: 100%;
          display: inline-block;
          font-weight: 500;
          height: 1.2rem;
          line-height: 1.2rem;
          margin: 0 0 8px;
        }
        input{
          flex: 1;
          box-sizing: border-box;
          min-height: 39px;
          max-width: 100% !important;
        }
        input,p{
          // line-height: 39px;
          color: #787878;
        }
        .button{
          max-width: none;
        }
      }
      .divider{
        display: inline-block;
        height: 2px;
        background-color: rgba(37, 37, 37, 0.1);
        margin: 24px 0;
        width: 100%;
      }
      .delete_page_button{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
        background-color: #006C3E;
        line-height: 40px;
        text-transform: uppercase;
        text-align: center;
        font-weight: 500;
        color: #fff;
        border-radius: 8px;
        margin: 0 0 24px 0;
        margin-top: 0 !important;
        span{
          display: inline-block;
          height: 40px;
          width: 40px;
          background-image: url(../../public/img/page/delete_white.svg);
          background-position: center;
          background-repeat: no-repeat;
        }
      }
      >div{
        margin-top: 10px;
        input:not([type="checkbox"]){
          width: 100%;
        }
        .subheader{
          display: inline-block;
          line-height: 39px;
          height: 39px;
          margin: 0 20px 0 0;
          color: #787878;
          font-weight: 500;
        }
      }
    }
    .files{
      width: 100%;
    }
  }
  @media screen and (max-width: 599px) and (orientation: portrait){
  }
  @media screen and (width: 1024px) and (height: 768px), screen and (width: 1180px) and (height: 820px){
  }
</style>
